@import '../node_modules/semantic-ui-css/semantic.min.css';

body {
  margin: 0;
  padding: 0;
  font-family: 'Rubik', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input[type='text']:focus,
input[type='password']:focus {
  outline: none !important;
  border-color: #532d62 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
